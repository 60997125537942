import React from 'react'
import './header.css'
import ME from '../../assets/me.png'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <div className='container_me'>
          <div className='me'>
            <img src={ME} alt="me" />
          </div>
        </div>
        <h1>Alin Rusu</h1>
        <HeaderSocials />
          <div className='hero_text'>
              <div className='title'>
                <h2>Versatile creative developer and designer dedicated to crafting distinctive web experiences. With a passion for innovation and a commitment to excellence, I specialize in delivering unique and impactful digital solutions that seamlessly blend cutting-edge design and robust development. Elevate your online presence with a tailored approach to user-centric, visually striking, and technologically advanced web solutions.
                </h2> 
              </div>
            </div>
        <h5 className="text-light"></h5>
        <a href="#contact" className='scroll__down'>Scroll Down</a>
      </div>
    </header>
  )
}

export default Header