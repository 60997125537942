import React from 'react'
import './nav.css'
import { AiOutlineHome } from "react-icons/ai";
import { LuUser } from "react-icons/lu";
import { BsBook } from "react-icons/bs";
import { RiServiceLine} from "react-icons/ri"
import { TbMessage2 } from "react-icons/tb";
import { useState } from 'react';



const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
      <a href="#" onClick={() => setActiveNav('#a')} className={activeNav === '#' ? 'active' : ''}><AiOutlineHome /></a>
      <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><LuUser /></a>
      <a href="#experience" onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}><BsBook /></a>
      <a href="#services" onClick={() => setActiveNav('#services')} className={activeNav === '#services' ? 'active' : ''}><RiServiceLine /></a>
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><TbMessage2 /></a>
    </nav>
  )
}

export default Nav