import React from 'react'
import './experience.css'
import { BsFillPatchCheckFill } from "react-icons/bs";



const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className='container experience__container'>
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article className='experience__details'>
                <BsFillPatchCheckFill />
                <h4>HTML</h4>
            </article>
            <article className='experience__details'>
                <BsFillPatchCheckFill />
                <h4>CSS</h4>
            </article>
            <article className='experience__details'>
                <BsFillPatchCheckFill />
                <h4>JavaScript</h4>
            </article>
            <article className='experience__details'>
                <BsFillPatchCheckFill />
                <h4>REACT</h4>
            </article>
            <article className='experience__details'>
                <BsFillPatchCheckFill />
                <h4>Responsive Design</h4>
            </article>
            <article className='experience__details'>
                <BsFillPatchCheckFill />
                <h4>UI/UX Design</h4>
            </article>
          </div>
        </div>
        {/* END OF FRONTEND */}
        <div className="experience__backend">
        <h3>Backend Development</h3>
          <div className="experience__content">
            <article className='experience__details'>
                <BsFillPatchCheckFill />
                <h4>PHP</h4>
            </article>
            <article className='experience__details'>
                <BsFillPatchCheckFill />
                <h4>Laravel</h4>
            </article>
            <article className='experience__details'>
                <BsFillPatchCheckFill />
                <h4>MySQL</h4>
            </article>
            <article className='experience__details'>
                <BsFillPatchCheckFill />
                <h4>NodeJS</h4>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience