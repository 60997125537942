import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";


const About = () => {
  return (
    <section id='about'>
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon' />
              <h5>Experience</h5>
              <small></small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon' />
              <h5>Clients</h5>
              <small></small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon' />
              <h5>Projects</h5>
              <small></small>
            </article>
          </div>
          <section className='about__text'>
            <p>Welcome to my "About" section! I'm Alin, a programming enthusiast With a background in both front-end and back-end web development.
              My journey into the world of development began with a fervent curiosity for emerging technologies and a desire to turn creative ideas into functional realities. With advanced skills in languages such as JavaScript,  React, Php, Laravel, and MySQL, I am capable of managing complex projects from start to finish.</p>

            <p> My constant thirst for learning drives me to stay updated on the latest technological trends, allowing me to adopt new tools and methodologies to consistently enhance my skills. Am motivated by the challenge of solving complex problems and creating solutions that exceed expectations.</p>

            <p>I am excited to collaborate on new projects and contribute to growth and success through my commitment and dedication to technical excellence. Thank you for visiting my "About" page, and I hope to have the opportunity to collaborate with you to create extraordinary solutions.</p>

            <a href="#contact" className='btn'>Let's Talk</a>
          </section>
        </div>
      </div>
    </section>
  )
}

export default About