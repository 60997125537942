import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.png'
import IMG2 from '../../assets/portfolio2.png'
import IMG3 from '../../assets/portfolio3.png'
import IMG4 from '../../assets/inprogress.jpg'


const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className='container portfolio__container'>
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
             <img src={IMG1} alt="" />
          </div>
          <h3>Links to social media profiles. </h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com/rusualin0031/mini-site-html-css?tab=readme-ov-file" className='btn btn' target='_blank'>Github</a>
            <a href="https://rusualin0031.github.io/mini-site-html-css/" className='btn btn' target='_blank'>Live Demo</a>
            </div>
        </article>
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
             <img src={IMG2} alt="" />
          </div>
          <h3>Black Jack Game </h3>
            <div className="portfolio__item-cta">
            <a href="https://github.com/rusualin0031/black-jack-game?tab=readme-ov-file" className='btn btn' target='_blank'>Github</a>
            <a href="https://rusualin0031.github.io/black-jack-game/" className='btn btn' target='_blank'>Live Demo</a>
            </div>
        </article>
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
             <img src={IMG3} alt="" />
          </div>
          <h3>Modern UI/UX GPT-3 </h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com/rusualin0031/project-alin-GPT3-OpenAI" className='btn btn' target='_blank'>Github</a>
            <a href="https://rusualin0031.github.io/project-alin-GPT3-OpenAI/" className='btn btn' target='_blank'>Live Demo</a>
            </div>
        </article>
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
             <img src={IMG4} alt="" />
          </div>
          <h3>This is a portfolio item title </h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com/rusualin0031/" className='btn btn' target='_blank'>Github</a>
            <a href="https://github.com/rusualin0031/" className='btn btn' target='_blank'>Live Demo</a>
            </div>
        </article>
      </div>
    </section>
  )
}

export default Portfolio